export let ChirailsModals = {
  setup: function() {
    // Flash errors
    if ($('div#flash-modal').length) {
      $('.overlay').addClass('is-shown');
      $('div#flash-modal').addClass('is-shown');
      // $('html,body').addClass('no-scroll');
    }

    // Log out modal trigger
    $('.modal-logout').on('click', function(e) {
        e.preventDefault();
        $('.overlay').addClass('is-shown');
        $('.logout-alert').addClass('is-shown');
        //$('html,body').addClass('no-scroll');
    });

    // Sluit modal en overlay
    $('.modal__close').on('click', function(e) {
      e.preventDefault();
      $('.overlay').removeClass('is-shown');
      $('.modal').removeClass('is-shown');
      $('.modal').removeClass('is-visible');
      $('html,body').removeClass('no-scroll');
    });

    // Sluit notificatie
    $('.notificatie').on('click', function() {
      $('.notificatie').removeClass('is-visible');
    });
  },
}

