function initializeChirailsWebsite() {
  ChirailsModals.setup();

  // Navigatie etc ------------------------------------------------------------------------
  // Navigatie mobile trigger
  $('.nav__trigger--toggler').on('click', function() {
    $(this).toggleClass('is-active');
    $('.nav__wrapper').toggleClass('is-hidden--md-down'); // Dit geeft alleen zichtbaar effect op desktop!
  });

  // Toggle navbar border bottom class on scroll
  $(window).scroll(function() {
    var scroll = $(window).scrollTop();
    if (scroll >= 96) {
      $('header').addClass("is-scrolled");
    } else {
      $('header').removeClass("is-scrolled");
    }
  });

  // Algemene dingen ----------------------------------------------------------------------

  // Scroll down homepage hero
  $("#scrollDown").click(function() {
    var gotoPos = $("#section-introduction").offset().top - 64;
    $([document.documentElement, document.body]).animate({
      scrollTop: gotoPos
    }, 200);
  });


  if(window.location.hash) {
    // Escape css karakters in jquery selector
    p = $((window.location.hash.replace(/(:|\.)/g,'\\$1')));
    if (p.length > 0) {
      $('html,body').animate({scrollTop: p.offset().top-350}, 'slow');
    }
  }

  // Chirails Esc key dingen sluiten ------------------------------------------------------------
  $(document).keyup(function(e) {
    if (($('html,body').hasClass('no-scroll')) && e.keyCode === 27) $('html,body').removeClass('no-scroll');
    if (($('.overlay').hasClass('is-shown')) && e.keyCode === 27) $('.overlay').removeClass('is-shown');
    if (($('.modal').hasClass('is-shown')) && e.keyCode === 27) $('.modal').removeClass('is-shown');
    if (($('.notificatie').hasClass('is-visible')) && e.keyCode === 27) $('.notificatie').removeClass('is-visible');
  });
}

document.addEventListener('turbo:load', function() {
  initializeChirailsWebsite();
});
