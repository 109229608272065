import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  async accepteer_cookies(event) {
    event.preventDefault()
    const response = await post('/accepteer_cookies', { responseKind: 'turbo_stream' })
    if (!response.ok) {
      console.error('Er gaat iets fout.')
    }
  }

  async wijs_cookies_af(event) {
    event.preventDefault()
    const response = await post('/wijs_cookies_af', { responseKind: 'turbo_stream' })
    if (!response.ok) {
      console.error('Er gaat iets fout.')
    }
  }

  scroll_to_top() {
    window.scrollTo(0, 0)
  }
}
